<template>
  <v-app class="mx-auth mx-auth-center">

    <img class="mx-auth-logo" src="/assets/images/logo-primary.png" alt="logo">
    <span class="mx-auth-version">{{ options.version }}</span>

    <v-card width="500" elevation="3" class="ma-auto pa-7 py-9">
      <v-card-title class="mt-n2 mb-4">
        <h1 class="display-1">{{ $t('auth.form.password.reset.title') }}</h1>
      </v-card-title>
      <v-card-text>
        <v-form @submit.prevent="login" ref="signUpForm" v-model="formValidity">
          <v-text-field
              outlined
              v-model="form.token"

              :label="$t('auth.form.password.reset.token')"
              required
              hide-details
              class="mb-5"
          ></v-text-field>
          <v-text-field
              outlined
              v-model="form.password"
              :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
              :label="$t('auth.form.password.reset.new_password')"
              hide-details
              class="mb-5"
          ></v-text-field>
          <v-text-field
              outlined
              v-model="form.password_confirmation"
              :append-icon="password_confirmation ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
              :type="password_confirmation ? 'text' : 'password'"
              @click:append="password_confirmation = !password_confirmation"
              :label="$t('auth.form.password.reset.new_password_confirmation')"
              hide-details
              class="mb-4"
          ></v-text-field>
        </v-form>
        <v-alert
            v-if="error"
            border="left"
            color="red"
            type="error"
        >{{ $t('auth.form.password.reset.error') }}</v-alert>
      </v-card-text>
      <v-card-actions class="d-flex align-center">
        <v-btn
            :loading="preloader"
            large color="primary"
            class="px-8 mr-6"
            @click="login"
            :disabled="!formValidity"
        >
          {{ $t('auth.form.password.reset.btn.reset') }}
        </v-btn>
        <router-link :to="{name: 'login'}" class="mr-2">{{ $t('auth.form.password.forgot.btn.login') }}</router-link>
      </v-card-actions>
    </v-card>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      showPassword: false,
      password_confirmation: false,
      form: {
        token: '',
        password: '',
        password_confirmation: ''
      },
      error: false,
      preloader: false,
      formValidity: false
    }
  },
  created() {
    this.form.token = this.$route.query.token
  },
  methods: {
    resetValidation() {
      this.$refs.signUpForm.resetValidation()
    },
    resetForm() {
      this.$refs.signUpForm.reset()
    },

    login() {
      this.preloader = true
      this.error = false
      this.$store.dispatch('passwordReset', this.form).then(() => {
        this.$router.replace({
          name: 'dashboard'
        })
      }).catch(error => {
        switch (error.response.status) {
          case 422:
            this.error = true;
            break;
        }
        this.preloader = false
      })
    }
  }
}
</script>